import React from 'react'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import { Navigate, Route, Routes } from 'react-router-dom'

import LogOutButton from '../../components/LogOutButton'

import UserSearchPage from './UserSearchPage'

const AuthPages = () => (
  <Container className="vh-100 overflow-hidden" fluid>
    <Row>
      <Col className="d-flex justify-content-end pt-2">
        <LogOutButton />
      </Col>
    </Row>
    <Routes>
      {/* 
      // TODO: On this page you should create input with search for users and list of users with link to their page -> /users/:id
      */}
      <Route element={<UserSearchPage />} path="/users/search" />
      {/* // TODO: When landed on this page, you should fetch all profiles of user  */}
      {/* <Route element={<UserPage />} path="/users/:id" /> */}
      <Route element={<Navigate to="/users/search" replace />} path="*" />
    </Routes>
  </Container>
)

export default AuthPages
