import Input from '@opoint/infomedia-storybook/dist/components/Input'
import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

//TODO: On this page you should create input with search for users and list of users with link to their page -> /users/:id
// ! You should use Tanstack Query + Axios for fetching data
const UserSearchPage = () => (
  <Row className="align-items-center text-center h-100">
    <Col lg={{ span: 6, offset: 3 }}>
      <Input.Text placeholder="Enter customer name or id" />
    </Col>
  </Row>
)

export default UserSearchPage
