import { useOpointAuthState } from '@opoint/authjs-react'
import React from 'react'

import AuthPages from '../../pages/AuthPages'
import LoadingPage from '../../pages/LoadingPage'

const PagesWrapper = () => {
  const authState = useOpointAuthState()

  if (authState === undefined) {
    return <LoadingPage />
  }

  return <AuthPages />
}

export default PagesWrapper
