import Loader from '@opoint/infomedia-storybook/dist/components/Loader'
import React from 'react'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'

const LoadingPage = () => (
  <Container>
    <Row className="vh-100 align-items-center">
      <Col className="d-flex justify-content-center">
        <Loader />
      </Col>
    </Row>
  </Container>
)

export default LoadingPage
