import { OpointAuthContext, useOpointAuth } from '@opoint/authjs-react'
import { getConfig } from '@opoint/infomedia-storybook/dist/config'
import React from 'react'
import { BrowserRouter } from 'react-router-dom'

import PagesWrapper from './components/PagesWrapper'
import { autoLogin } from './configuration/constants'

const authUrl = getConfig('domains.auth')
const queryBuilderUrl = getConfig('domains.queryBuilder')

const App = () => {
  const auth = useOpointAuth(authUrl, queryBuilderUrl, autoLogin)

  return (
    <BrowserRouter>
      <OpointAuthContext.Provider value={auth}>
        <PagesWrapper />
      </OpointAuthContext.Provider>
    </BrowserRouter>
  )
}

export default App
