import { OpointAuthContext } from '@opoint/authjs-react'
import Button from '@opoint/infomedia-storybook/dist/components/Button'
import React, { useContext } from 'react'

const LogOutButton = () => {
  const auth = useContext(OpointAuthContext)

  const handleLogOut = () => auth?.logout()

  return <Button.Link onClick={handleLogOut}>Log Out</Button.Link>
}

export default LogOutButton
